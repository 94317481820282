import axios from "axios";

export default {
  data() {
    return {
      uploadedFilesCount: 0,
    };
  },
  methods: {
    handleError(action, error) {
      console.error(`Error ${action}`, error);
    },
    createFormData(fields) {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      return formData;
    },
    async uploadFilesSequentially({ assistantId, files }) {
      if (!assistantId) return;

      for (const file of Array.from(files)) {
        await this.uploadSingleFile(assistantId, file);
      }
    },
    async uploadSingleFile(assistantId, file) {
      const fileFormData = this.createFormData({ file });

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/assistants/${assistantId}/files`,
          fileFormData,
        );

        this.uploadedFilesCount += 1;
      } catch (error) {
        this.openErrorsAlert("Error uploading file", file.name);
        this.handleError(`uploading file: ${file.name}`, error);
      }
    },
  },
};
