import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import Forgot from "../views/ForgotView.vue";
import Recover from "../views/RecoverView.vue";
import Error from "../views/ErrorView.vue";
import Notification from "../views/NotificationView.vue";
import AdminPanel from "../views/AdminPanelView.vue";
import Assistants from "../views/AssistantsView.vue";
import MyProjects from "../views/MyProjectsView.vue";
import Programs from "../views/ProgramsView.vue";
import newItinerary from "../views/newItineraryView.vue";
import Forum from "../views/ForumView.vue";
import Parts from "../views/PartsView.vue";
import Faqs from "../views/FaqsView.vue";
import NewProjectV2 from "../views/NewProjectV2View.vue";
import NewTrack from "../views/NewTrackView.vue";
import EditProfile from "../views/EditProfileView.vue";
import Tracks from "../views/TrackView.vue";
import Templates from "../views/TemplateView.vue";
import Register from "../views/RegisterView.vue";
import ManageSpace from "../views/ManageSpaceView.vue";
import TermsConditions from "../views/TermsConditionsView.vue";
import PrivacyPolicy from "../views/PrivacyPolicyView.vue";
import Cdti2 from "../views/CdtiTestView.vue";
import Cdti from "../views/CdtiView.vue";
import Invertibility from "../views/InvertibilityIndexView.vue";
import NewTemplate from "../views/NewTemplateView.vue";
import MaintenanceView from "../views/MaintenanceView.vue";
import Version from "@/views/VersionView.vue";
import NewAssistant from "@/components/assistants/NewAssistant.vue";
import EditAssistant from "@/components/assistants/EditAssistant.vue";
import AssistantDetails from "@/components/assistants/AssistantDetails.vue";
import Cookies from "js-cookie";
import ConfigProjectView from "@/views/ConfigProjectView.vue";

const routes = [
  {
    path: "/",
    name: "Home ",
    component: Home,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      redirectHome(to, from, next);
    },
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: () => {
      Cookies.remove("token");
      window.location.href = "/login";
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: Forgot,
    beforeEnter: (to, from, next) => {
      redirectHome(to, from, next);
    },
  },
  {
    path: "/recover-password/:token/:email",
    name: "Recover",
    component: Recover,
    beforeEnter: (to, from, next) => {
      redirectHome(to, from, next);
    },
  },
  {
    path: "/signup/:token",
    name: "Register",
    component: Register,
    beforeEnter: (to, from, next) => {
      if (Cookies.get("token")) {
        new Promise((resolve) => {
          Cookies.remove("token");
          resolve();
        }).then(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("redirect")) {
        const url = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        next({ path: url });
      } else {
        guard(to, from, next);
      }
    },
  },
  {
    path: "/notifications",
    name: "Notification",
    component: Notification,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/adminpanel",
    name: "AdminPanel",
    component: AdminPanel,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/assistants",
    name: "Assistants",
    component: Assistants,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/assistant-details/:id",
    name: "AssistantDetails",
    component: AssistantDetails,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/new-assistant",
    name: "NewAssistant",
    component: NewAssistant,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/edit-assistant/:id",
    name: "EditAssistant",
    component: EditAssistant,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/my-projects",
    name: "MyProjects",
    component: MyProjects,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/forum",
    name: "Forum",
    component: Forum,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/forum/:post_id",
    name: "Forum2",
    component: Forum,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/forum-thread/:post_id",
    name: "Forum3",
    component: Forum,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/bulletin",
    name: "Bulletin",
    component: Forum,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/bulletin/:post_id",
    name: "Bulletin2",
    component: Forum,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/programs",
    name: "Programs",
    component: Programs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/itinerary/:project_id",
    name: "Itinerary",
    component: newItinerary,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/config/:project_id",
    name: "ConfigProject",
    component: ConfigProjectView,
    beforeEnter: (to, from, next) => {
      const pathParts = extractPathParams(window.location.pathname);
      to.meta.origin = { name: from.name, ...pathParts };
      guard(to, from, next);
    },
    props: (route) => ({ origin: route.meta.origin }),
  },
  {
    path: "/parts/:project_id/:station_id/:solution_id",
    name: "Parts",
    component: Parts,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: Error,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/faqs/:faq_id",
    name: "Faqs2",
    component: Faqs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/feedback/support-great/",
    name: "Faqs3",
    component: Faqs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/customer-support/",
    name: "Faqs4",
    component: Faqs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/feedback/support-message-sent/",
    name: "Faqs5",
    component: Faqs,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/new-project",
    name: "NewProject",
    component: NewProjectV2,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/new-track",
    name: "NewTrack",
    component: NewTrack,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/new-template/:template_id?",
    name: "NewTemplate",
    component: NewTemplate,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/tracks",
    name: "Tracks",
    component: Tracks,
    beforeEnter: (to, from, next) => {
      next("/templates");
    },
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/cdti",
    name: "Cdti",
    component: Cdti,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/invertibility-index/:project_id",
    name: "Invertibility",
    component: Invertibility,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/autologin/:token/:id",
    name: "Autologin",
    component: Home,
  },
  {
    path: "/manageSpaces/:token",
    name: "ManageSpace2",
    component: ManageSpace,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/terms-conditions",
    name: "Terms & Conditions",
    component: TermsConditions,
    beforeEnter: (to, from, next) => {
      redirectHome(to, from, next);
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    beforeEnter: (to, from, next) => {
      redirectHome(to, from, next);
    },
  },
  {
    path: "/cdti-test",
    name: "Cdti ",
    component: Cdti2,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceView,
  },
  {
    path: "/version",
    name: "Versions",
    component: Version,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
];

const extractPathParams = (pathname) => {
  const pathParts = pathname.split("/");
  const [, , project_id, station_id, solution_id] = pathParts || [];

  return { project_id, station_id, solution_id };
};

const guard = function (to, from, next) {
  const token = Cookies.get("token");
  if (token) {
    localStorage.removeItem("redirect");
    next();
  } else {
    localStorage.setItem("redirect", to.path);
    next("/login");
  }
};

const redirectHome = function (to, from, next) {
  const token = Cookies.get("token");
  if (token) {
    next("/home");
  } else {
    next();
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   // replace '/maintenance' with the path of your maintenance page
//   if (to.path !== "/maintenance") {
//     next({ name: "Maintenance" });
//   } else {
//     next();
//   }
// });

export default router;
