<template>
  <div v-if="files?.length !== 0" class="file-tab">
    <div v-for="(file, index) in files" :key="index">
      <div
        v-if="file.deleted === 0"
        class="file"
        :class="{ 'bg-not-change': !canChange }"
      >
        <div class="file-icon-upload">
          <img
            class="icon-upload"
            src="/img/itinerario/file.svg"
            :alt="getDescription(file)"
          />
          <span class="file-type">{{ getExtension(file)?.toUpperCase() }}</span>
        </div>
        <div class="file-info">
          <span class="filename">{{ file.file_name }}</span>
          <span class="filename">{{ getDescription(file) ?? "" }}</span>
        </div>
        <button
          v-if="canChange"
          class="close-btn"
          @click="handleRemoveFile(index)"
        >
          &times;
        </button>
      </div>
      <div v-else class="file" :class="{ 'bg-not-change': !canChange }">
        <div class="file-icon-upload">
          <img
            class="icon-upload"
            src="/img/itinerario/file.svg"
            :alt="getDescription(file)"
          />
          <span class="file-type">{{ getExtension(file)?.toUpperCase() }}</span>
        </div>
        <div class="file-info">
          <span class="filename">Archivo eliminado {{ file.file_name }}</span>
          <span class="filename">{{ getDescription(file) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileType } from "./model/fileType";

export default {
  props: ["lang", "files", "canChange"],
  emits: ["removeFile"],
  data() {
    return {
      localFiles: [...this.files],
    };
  },
  watch: {
    files(newFiles) {
      this.localFiles = [...newFiles];
    },
  },
  methods: {
    handleRemoveFile(index) {
      this.$emit("removeFile", index);
    },
    getExtension({ file_name }) {
      return fileType.getExtension(file_name);
    },
    getDescription(file) {
      const extension = this.getExtension(file);
      return fileType.getDescription(this.lang, extension);
    },
  },
};
</script>

<style>
.file-tab {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.file-icon-upload {
  position: relative;
  width: 40px;
  height: 50px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.file {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 10px;
  height: 82px;
  grid-template-columns: auto 1fr auto;
  max-width: 300px;
  min-width: 200px;
  padding: 0 10px;
  position: relative;
  width: auto;
}

.icon-upload {
  width: 40px !important;
  /* Ajusta el tamaño del ícono */
  height: auto !important;
  border-radius: 5px;
  /* Redondea la imagen si es necesario */
}

.file-info {
  display: grid;
  grid-template-rows: auto auto;
  /* Dos filas, una para el nombre y otra para la descripción */
  align-items: center;
  /* Alinear los elementos en el centro vertical */
  justify-content: start;
  /* Alinear los elementos a la izquierda horizontalmente */
  gap: 4px;
  /* Espacio entre la fila del nombre y la descripción */
  padding-left: 10px;
  /* Espaciado desde la izquierda para separar del ícono */
}

.filename {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  color: #333;
  display: -webkit-box;
  font-size: 13px;
  font-weight: bold;
  line-clamp: 2;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  text-wrap: balance;
}

.file-type {
  position: absolute;
  bottom: 15px;
  /* Ajusta la distancia desde la parte inferior */
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  font-weight: bolder;
  padding: 3px 8px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 12px;
  background-color: gray;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #888;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: 0px;
}

.close-btn:hover {
  color: #000;
}

.bg-not-change {
  background-color: #ec6288 !important;
}

.bg-not-change .filename,
.bg-not-change .file-type,
.bg-not-change .icon {
  color: white !important;
}
</style>
